import { ActivatedRoute, Router } from '@angular/router';
import {
  AddLicence,
  DeleteLicence,
  LogAnalyticEvent,
  UpdateLicence
} from 'src/app/state/trainingRequirement.actions';
import { Select, Store } from '@ngxs/store';
import { TrainingRequirement, TrainingType } from 'src/app/models/TrainingRequirement';

import { Authority } from 'src/app/models/Authority';
import { BaseLicenceComponent } from '../base-licence.page';
import { Component } from '@angular/core';
import { EditMode } from 'src/app/models/EditMode';
import { FaaAuthorityDescription } from 'src/app/models/AuthorityDescription';
import { FormContentComponentLayout } from 'src/app/shared/components/form-content/form-content.component';
import { Guid } from 'guid-typescript';
import { LicenseTypes } from 'src/app/data/licenseTypes';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { ValidationErrorTypes } from '../../../../services/tca-validation.service';
import { LicenseCategory } from 'src/app/data/licenseCategory';

@Component({
  selector: 'app-faa-licence-page',
  templateUrl: './faa-licence-page.component.html',
  styleUrls: ['./faa-licence-page.component.less']
})
export class FaaLicencePageComponent extends BaseLicenceComponent {
  public formContentComponentLayout = FormContentComponentLayout;

  otherLicenseType: LicenseTypes = LicenseTypes.Other;
  regulationAgency: string;

    licenceTypes = [
    { id: LicenseTypes.ATP, description: `Airline Transport Pilot License (${LicenseTypes.ATP})` },
    { id: LicenseTypes.Commercial, description: LicenseTypes.Commercial },
    { id: LicenseTypes.Other, description: LicenseTypes.Other }
  ];

  faaLicenceName = this.authorityDescription.authority.toLocaleUpperCase() + ' Certificate';
  licenceCategoryType : LicenseCategory = LicenseCategory.Existing;
  enableDateLastInitial = false;
  authority = Authority;
  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;
  currentTrainingRequirement: TrainingRequirement;
  isFormDirty = false;
  constructor(
    route: ActivatedRoute,
    store: Store,
    private router: Router,
    private location: Location
  ) {
    super(route, store, new FaaAuthorityDescription());
  }

  onInit() {

    if (this.route.snapshot.data) {
      this.enableDateLastInitial = this.route.snapshot.data['enableDateLastInitial'];
    }
    this.subscribe(this.currentTrainingRequirement$.subscribe((trainingRequirement) => {
      this.currentTrainingRequirement = trainingRequirement;

      if (this.currentTrainingRequirement.trainingType === TrainingType.Initial && this.currentTrainingRequirement.regulationAgency === RegulationAgencyName.TC) {
        this.enableDateLastInitial = false;
      }

      if (this.currentTrainingRequirement.trainingType === TrainingType.Recurrent && this.currentTrainingRequirement.regulationAgency === RegulationAgencyName.TC) {
        this.enableDateLastInitial = true;
      }
      const existingLicence = this.currentTrainingRequirement?.licences?.find(
            licence => licence.authority === this.authority.FAA
        );

      if (existingLicence?.licenceType?.name) {
        this.selectedLicenceType = existingLicence.licenceType.name;
           this.licence = existingLicence;
      } 
      else {
          this.selectedLicenceType = this.licenceTypes[0].id;
      }

      if (existingLicence?.certificateNumber || existingLicence?.licenceCategoryType === LicenseCategory.Existing) {
            this.licenceCategoryType = LicenseCategory.Existing;

      } else if(existingLicence?.licenceCategoryType === LicenseCategory.Future) {
            this.licenceCategoryType = LicenseCategory.Future;
      }
    }));
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Load', 'FAA Licence Page'));
    this.regulationAgency = RegulationAgencyName.FAA;

   
  }

  onDestroy() {
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Unload', 'FAA Licence Page'));
  }

  onDelete() {
    this.store.dispatch(new DeleteLicence(this.licence.id));
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Delete', 'FAA Licence Page'));
    this.redirectToTcaPage();
  }

  onCancel() {
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Back', 'FAA Licence Page'));
    this.redirectToTcaPage();
  }

  setLastTCDate(date: any) {
    this.licence.lastTCDate = date;
  }

  onSave() {
    this.licence.authority = Authority.FAA;
    this.licence.licenceType.name = !!this.selectedLicenceType
      ? this.selectedLicenceType
      : this.licenceTypes[0].id;
    this.licence.isPerformanceBasedNavigation = false;
    this.licence.licenceCategoryType = this.licenceCategoryType;
    if(this.licence.licenceCategoryType == LicenseCategory.Future){
      this.licence.certificateNumber = null;
    }
    this.updateLicenceError(this.licence);
    if (
      (!!this.licenceValidationErrors && this.licenceValidationErrors.licenceErrors.length > 1) ||
      (!!this.licenceValidationErrors &&
        this.licenceValidationErrors.licenceErrors.length === 1 &&
        this.licenceValidationErrors.licenceErrors[0] !== ValidationErrorTypes.LICENCE_EXPIRED)
    ) {
      return;
    }

    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Save', 'FAA Licence Page'));
    if (this.mode === EditMode.New) {
      this.licence.id = Guid.create().toString();
      this.store.dispatch(new AddLicence(this.licence));
    } else if (this.mode === EditMode.Edit) {
      this.store.dispatch(new UpdateLicence(this.licence));
    }
    this.redirectToTcaPage();
  }

  formDirtyChanged(val: boolean) {
    if (val) {
      this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Start', 'FAA Licence Page'));
    }
  }

  saveLicenceType(licenceType: any) {
    this.selectedLicenceType = licenceType.id;
    this.licence.licenceType.value = null;

    if (this.selectedLicenceType === LicenseTypes.Other) {
      this.licence.licenceType.value = null;
      this.licence.licenceType.name = LicenseTypes.Other;
    } else {
      this.licence.licenceType.name = this.selectedLicenceType;
  }
    this.updateLicenceError(this.licence);
  }

  toggleLicenseCategory(isChecked: boolean, category: string) {
        if (isChecked){
          this.licenceCategoryType = category as LicenseCategory;
          this.isFormDirty = true;
        }
    }

  onLicenceTypeChange(licenceType: any): boolean {
    return this.selectedLicenceType?.toUpperCase() === licenceType.id.toUpperCase();
  }


  redirectToTcaPage(): void {
    this.location.back();
  }
}
